<template>
  <div class="z-10 transform">
    <div class="w-full">
      <form
        @submit.prevent="updateRouting(this.id, 'Routing updated successfully')"
        class="shadow space-y-8 divide-y divide-gray-200 form_padding z-10"
        method="POST"
      >
        <div class="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Update Route
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Add new routing information below.
            </p>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="first-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                List name
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="this.form.listName"
                  required=""
                  type="text"
                  name="first-name"
                  id="first-name"
                  autocomplete="given-name"
                  class="max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300 rounded "
                  :placeholder="this.name"
                />
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="country"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Geographic Model
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <select
                  v-model="this.form.geoModel"
                  required=""
                  id="country"
                  name="country"
                  autocomplete="country"
                  class="max-w-lg block focus:encom_border_input  w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded "
                >
                  <option value="1">French departments</option>
                </select>
              </div>
            </div>

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Fallback Destination 1
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="this.form.fallback1"
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300 rounded "
                />
              </div>
            </div>
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Fallback Destination 2
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="this.form.fallback2"
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300 rounded "
                />
              </div>
            </div>
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Fallback Destination 3
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="this.form.fallback3"
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300 rounded "
                />
              </div>
            </div>
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="last-name"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Fallback Destination 4
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  v-model="this.form.fallback4"
                  type="text"
                  name="last-name"
                  id="last-name"
                  autocomplete="family-name"
                  class="max-w-lg block w-full shadow-sm focus:encom_border_input  sm:max-w-xs sm:text-sm border-gray-300 rounded "
                />
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5">
          <div class="flex justify-end">
            <a
              href="javascript:void(0)"
              v-on:click="$emit('closeForm')"
              class="bg-white py-2 px-4 border border-gray-300  shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              :class="
                `rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium  body__table--header text-white`
              "
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";

const account = localStorage.getItem("account");

export default {
  props: ["id", "name"],
  components: { ConfirmationDialog },
  data() {
    return {
      account,
      form: {
        listName: "",
        geoModel: "1",
        fallback1: "",
        fallback2: "",
        fallback3: "",
        fallback4: "",
      },
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
    };
  },
  methods: {
    updateRouting(id, msg) {
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists/${id}`,
        params: {
          customerAccount: this.account,
          listName: this.form.listName,
          geographicModel: this.form.geoModel,
          fallBackDestination1: this.form.fallback1,
          fallBackDestination2: this.form.fallback2,
          fallBackDestination3: this.form.fallback3,
          fallBackDestination4: this.form.fallback4,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((res) => {
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch((error) => {
          this.errorHandling(error);
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", this.errorDataResponse);
        });
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {
    this.form.listName = this.name;
  },
  watch: {
    name: function(val) {
      this.form.listName = this.name;
    },
  },
};
</script>

<style></style>
