<template>
 <PageHeading
  :title="$t('georouting')"
  :button-title="$t('newGeoRouting')"
  :has-search="true"
  :has-action-button="true"
  :has-meta-data="false"
  :has-permission="
   userPermissions.permissions.includes('smart-routings.*') ||
   userPermissions.permissions.includes('smart-routings.create')
  "
  @open="(this.openForm = !this.openForm), (this.openUpdateForm = false)"
  @update:search="$emit('update:search', $event)"
 />
 <div class="flex flex-col" v-if="this.openForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="overflow-hidden border rounded-md">
     <CreateNewRouting
      @closeForm="this.openForm = false"
      @activeBanner="$emit('activeBanner'), getRoutingLists()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
   </div>
  </div>
 </div>
 <div class="flex flex-col" v-if="this.openUpdateForm">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="overflow-hidden border rounded-md">
     <CreateNewRouting
      :editData="editData"
      :openUpdateForm="openUpdateForm"
      @closeForm="this.openUpdateForm = false"
      @activeBanner="$emit('activeBanner'), getRoutingLists()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
   </div>
  </div>
 </div>

 <div class="flex flex-col">
  <div class="-my-0 sm:-mx-6 lg:-mx-0">
   <div v-if="tbody?.length > 0" class="align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <RoutingLists
     :account="this.account"
     :thead="thead"
     :tbody="filteredDatas()"
     @openEditForm="(...args) => openEditForm(...args)"
     @duplicateList="duplicateList($event)"
     @deleteList="deleteList($event)"
     @activeBanner="$emit('activeBanner')"
     @successMsg="$emit('successMsg', $event)"
     @success="$emit('success', $event)"
     @sort="(this.currentSort = $event), sort($event)"
    />
   </div>

   <div v-else class="justify-center pt-20">
    <EmptyState
     :title="$t('emptyStateTitle', { title: $t('georouting') })"
     :subtitle="$t('emptyStateSubtitle', { title: $t('georouting') })"
    />
   </div>
  </div>
 </div>

 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";

import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import CreateNewRouting from "../components/CreateNewRouting.vue";
import EmptyState from "../components/EmptyState.vue";
import PageHeading from "../components/PageHeading.vue";
import RoutingLists from "../components/RoutingLists";
import UpdateRoutingForm from "../components/UpdateRoutingForm.vue";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 props: ["search"],
 components: {
  AskConfirmationDialog,
  CreateNewRouting,
  EmptyState,
  RoutingLists,
  PageHeading,
  UpdateRoutingForm,
 },
 data() {
  return {
   account,
   thead: [
    { name: "listName", sort: "name", isSort: false },
    { name: "idList" /*, sort: "lastName"*/, isSort: false },
    { name: "created_at", sort: "created_at", isSort: false },
    { name: "updated_at", sort: "updated_at", isSort: false },
   ],
   tbody: [],
   currentSort: "id",
   currentSortDir: "desc",
   openForm: false,
   openUpdateForm: false,
   listId: "",
   listName: "",
   key: false,
   editData: {},
   userPermissions,
  };
 },
 methods: {
  openEditForm(data) {
   this.openUpdateForm = true;
   this.openForm = false;
   this.editData = { ...data };
   window.scrollTo(0, 100);
  },
  async getRoutingLists() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists?customerAccount=${this.account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.$emit("setLoading", false);

    this.tbody = res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    this.errorHandling(error);
   }
  },
  filteredDatas() {
   return this.tbody.filter((res) => {
    if (this.search) {
     const searchLower = this.search.toLowerCase();

     const listNameMatch = res.listNameMatch.toLowerCase().includes(searchLower);
     const listIdMatch = String(res.listId).includes(searchLower);
     const customerAccountMatch = res.customerAccount.toLowerCase().includes(searchLower);

     const createdAtMatch = this.$d(res.created_at, "shortText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());
     const updatedAtMatch = this.$d(res.updated_at, "longText")
      .toLowerCase()
      .includes(searchLower.toLowerCase());

     return (
      listNameMatch || listIdMatch || customerAccountMatch || createdAtMatch || updatedAtMatch
     );
    }
    return res;
   });
  },

  filteredDatas() {
   return this.tbody.filter((list) => {
    return (
     String(list.listName).toLowerCase().includes(String(this.search).toLowerCase()) ||
     String(list.listId).includes(String(this.search))
    );
   });
  },
  duplicateList(id) {
   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists/${id}/replicate`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };

   axios
    .request(options)
    .then((response) => {
     let msg = this.$t("elementDuplicatedSuccessfully", { element: this.$t("routing") });
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
     this.getRoutingLists();
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  async deleteList(...args) {
   let id = args[0];
   let name = args[1];
   let title = this.$t("deleteGeoRouting", { name: name });
   let confirmationMessage = this.$t("deleteGeoRoutingMessage");
   let confirmButton = this.$t("delete");
   let goBack = this.$t("cancel");
   let successMsg = this.$t("elementDeletedSuccessfully", { element: this.$t("routing") });
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/geoRoutingLists/${id}`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then((response) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", successMsg);
      this.getRoutingLists();
     })
     .catch((error) => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
 },
 mounted() {
  this.getRoutingLists();
 },
};
</script>

<style></style>
